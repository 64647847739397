import React from "react";
import { Layout, Button, Typography } from "antd";
import "./TeamSelect.css"; // Import custom styles
import AppHeader from "./components/AppHeader/AppHeader";
import { useNavigate } from "react-router-dom";

const { Content } = Layout;

const TeamSelect = ({ setTeam = () => {} }) => {
  const navigate = useNavigate();
  return (
    <Layout>
      <AppHeader />
      <Content style={{ padding: "50px", height: 750 }}>
        <Typography.Title level={4} style={{ textAlign: "center" }}>
          Select Team
        </Typography.Title>
        <div className="button-container">
          <Button
            onClick={() => navigate("/blr")}
            type="primary"
            size="large"
            className="city-button"
            // disabled={true}
          >
            Bengaluru Team
          </Button>
          <Button
            onClick={() => navigate("/hyd")}
            type="primary"
            size="large"
            className="city-button"
          >
            Hyderabad Team
          </Button>
        </div>
      </Content>
    </Layout>
  );
};

export default TeamSelect;
