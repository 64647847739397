import ExcelJS from "exceljs";
import FileSaver from "file-saver";

const arrayToExcelAndDownload = (data, filename) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Sheet 1");
  worksheet.addRow(["Company Name", "Serial Number", "Summary", "Sentiment","Full Article","Link"]);
  data.forEach((row) => {
    worksheet.addRow(row);
  });

  workbook.xlsx.writeBuffer().then((buffer) => {
    const file = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    FileSaver.saveAs(file, filename);
  });
};

export default arrayToExcelAndDownload;
