/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  FileAddOutlined,
  SearchOutlined,
  StopOutlined,
  LinkOutlined,
  SyncOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import {
  AutoComplete,
  Breadcrumb,
  Button,
  Divider,
  Input,
  Layout,
  Modal,
  Spin,
  Table,
  Tag,
  Typography,
  Upload,
} from "antd";
import ButtonGroup from "antd/es/button/button-group";
import { saveAs } from "file-saver";
import React, { useEffect, useState } from "react";
import AppHeader from "../components/AppHeader/AppHeader";
import getToken from "../functions/getToken";
import rootUrl from "../rootUrl";
import Link from "antd/es/typography/Link";
import ExcelJS from "exceljs";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";

async function hasSheet(file, checkSheet = "CODING SHEET") {
  try {
    // Load the workbook from the file
    console.log(file);
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(file);

    const sheet = workbook.getWorksheet(checkSheet);
    if (sheet) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('Error occurred while checking for sheet named "":', error);
    return false;
  }
}

const getAuth = (spath) =>
  fetch(`${rootUrl}${spath}`, {
    headers: { Authorization: `Bearer ${getToken()}` },
    mode: "cors",
  });

const postAuth = (spath, data) =>
  fetch(`${rootUrl}${spath}`, {
    mode: "cors",
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
  });

const downloadFile = async (fn) => {
  try {
    const url = `/api/blr_download_excel?q=${fn}`;
    const filename = url.split("/").pop()?.includes(".xlsx")
      ? url.split("/").pop()
      : "tagfact-download.xlsx";
    // Fetch the data from the url
    const response = await getAuth(url);

    // Check if the request was successful
    if (!response.ok) {
      alert("Network response was not ok");
    }

    // Get the data as a Blob
    const blob = await response.blob();

    // Use file-saver to save the file
    saveAs(blob, filename);
  } catch (err) {
    alert("Failed to download the file: " + err.message);
  }
};

const ShowQuotes = ({ goBack, data }) => {
  return (
    <div>
      <Button icon={<ArrowLeftOutlined />} onClick={goBack}>
        Back
      </Button>
      <Typography.Title level={4}>Quotes</Typography.Title>
      <ul
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        {data?.data?.quotes?.map((x) => (
          <li key={x}>{x}</li>
        ))}
      </ul>
      <Typography.Title level={4}>Spokespeople</Typography.Title>
      <ul
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        {data?.data?.spokespeople?.map((x) => (
          <li key={x}>{x}</li>
        ))}
      </ul>
    </div>
  );
};
const ShowCriteriaDetails = ({ goBack, data, onForcePass, onForceFail }) => {
  const cdx = data?.data?.criteria_details;
  return (
    <div
      style={{
        margin: "15px",
        padding: "10px",
        borderRadius: "5px",
        backgroundColor: "#F2F8FC",
      }}
    >
      <div>
        <Button
          style={{
            marginBottom: "15px",
            backgroundColor: "#007BFF",
            color: "white",
            marginRight: 10,
          }}
          icon={<ArrowLeftOutlined />}
          onClick={goBack}
        >
          Back
        </Button>
        {data?.data?.status === "PASSING" ? (
          <Button
            style={{ marginRight: 10 }}
            onClick={() => onForceFail(data?.item_id)}
          >
            Force Fail
          </Button>
        ) : (
          <Button
            style={{ marginRight: 10 }}
            onClick={() => onForcePass(data?.item_id)}
          >
            Force Pass
          </Button>
        )}
      </div>
      <table
        style={{ border: "1px solid black", width: "100%", textAlign: "left" }}
      >
        {Object.keys(cdx)?.map((key) => (
          <tr style={{ borderBottom: "1px solid grey" }}>
            <td style={{ padding: "10px" }}>{key}</td>
            <td style={{ padding: "10px" }}>
              {cdx[key] === "PASSING" ? (
                <Tag
                  color="green"
                  style={{ cursor: "pointer" }}
                  onClick={() => {}}
                >
                  PASSING
                </Tag>
              ) : (
                <Tag
                  color="red"
                  style={{ cursor: "pointer" }}
                  onClick={() => {}}
                >
                  FAILING
                </Tag>
              )}
            </td>
          </tr>
        ))}
      </table>
    </div>
  );
};

const ShowOtherDetails = ({ goBack, data }) => {
  return (
    <div>
      <Button icon={<ArrowLeftOutlined />} onClick={goBack}>
        Back
      </Button>
      <div style={{ marginTop: 10 }}>
        <table
          style={{
            backgroundColor: "rgba(0,0,0,0.1)",
            borderRadius: 10,
            padding: 10,
            width: 800,
          }}
        >
          <tr style={{ width: 400 }}>
            <td style={{ fontWeight: "bold" }}>Main Story Driver</td>
            <td>{data?.topics?.main_story_driver}</td>
          </tr>
          <tr style={{ width: 400 }}>
            <td style={{ fontWeight: "bold" }}>Topic Codes</td>
            <td>
              {data?.topics?.topic_codes.map((x) => x.toString()).join(", ")}
            </td>
          </tr>
          <tr style={{ width: 400 }}>
            <td style={{ fontWeight: "bold" }}>Topic Messages</td>
            <td>
              {data?.topics?.topic_names.map((x) => x.toString()).join(", ")}
            </td>
          </tr>
          <tr style={{ width: 400 }}>
            <td style={{ fontWeight: "bold" }}>Messages Found</td>
            <td>
              {data?.messages?.message_codes
                .map((x) => x.toString())
                .join(", ")}
            </td>
          </tr>
        </table>
      </div>
      {/* <div>{JSON.stringify(data)}</div> */}
    </div>
  );
};
const ShowMetadata = ({ data, goBack }) => {
  const [activeItem, setActiveItem] = useState("Headline");
  const items = [
    "Headline",
    "Byline/Commentator",
    "Overall Tone",
    "Client Mentions",
    "Summary",
  ];
  return (
    <div>
      <Button icon={<ArrowLeftOutlined />} onClick={goBack}>
        Back
      </Button>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {items.map((x) => (
          <div
            onClick={() => {
              setActiveItem(x);
            }}
            style={{
              marginRight: 10,
              backgroundColor: activeItem === x ? "rgba(0,0,250,1)" : "white",
              color: activeItem === x ? "white" : "black",
              padding: 5,
              borderRadius: 5,
              cursor: "pointer",
            }}
          >
            {x}
          </div>
        ))}
      </div>
      <div
        style={{
          backgroundColor: "rgba(0,0,0,0.1)",
          padding: 10,
          borderRadius: 10,
        }}
      >
        {activeItem === "Headline" ? (
          <div>{JSON.stringify(data?.headline)}</div>
        ) : null}
        {activeItem === "Byline/Commentator" ? <div>{data?.byline}</div> : null}
        {activeItem === "Overall Tone" ? (
          <div>{data?.data.sentiment}</div>
        ) : null}
        {activeItem === "Client Mentions" ? (
          <div>
            {data?.data?.client_mentions?.map((i, n) => (
              <div>
                {n + 1} : {i}
              </div>
            ))}
          </div>
        ) : null}
        {activeItem === "Summary" ? <div>{data?.data?.summary}</div> : null}
      </div>
    </div>
  );
};

const DetailModal = ({
  onClose = () => {},
  isOpen = false,
  detailId,
  forceRefresh = () => {},
  onDownloadOutput = () => {},
}) => {
  const [mode, setMode] = useState("standard");
  const [target, setTarget] = useState(null);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (mode === "standard") setTarget(null);
  }, [mode]);

  useEffect(() => {
    if (isOpen && detailId) {
      setMode("standard");
      setIsLoading(true);
      postAuth("/api/blr_get_request_by_id", { request_id: detailId })
        .then(async (r) => {
          const rt = await r.json();
          console.log(rt);
          setData(rt);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [isOpen, detailId]);
  const colnames = [
    "Index",
    "Item Id",
    "Headline",
    "Byline",
    "PDF Link",
    "Metadata",
    "Sentiment",
    "Other Details",
    "Quotes",
    "Reject Criteria",
  ];
  let aidata = data?.ai_analysis_data?.data;

  const formattedAIData = aidata
    ? aidata
        ?.filter((i) => i)
        .map((i, n) => ({
          Index: n + 1,
          "Item Id": i?.item_id,
          Headline: i?.headline,
          Byline: i?.byline,
          "PDF Link": (
            <Link
              onClick={(e) => {
                e.preventDefault();
                window.open(i?.pdflink, "_blank");
              }}
              href={i?.pdflink}
            >
              Open
              <LinkOutlined style={{ marginLeft: 10 }} />
            </Link>
          ),
          Metadata: (
            <Button
              onClick={() => {
                setTarget(i);
                setMode("metadata");
              }}
            >
              View
            </Button>
          ),
          Sentiment: (
            <Tag
              color={
                i?.data?.sentiment === "POSITIVE"
                  ? "green"
                  : i?.data?.sentiment === "NEGATIVE"
                  ? "red"
                  : "yellow"
              }
            >
              {i?.data?.sentiment}
            </Tag>
          ),
          "Other Details": (
            <Button
              onClick={() => {
                setTarget(i);
                setMode("otherdetails");
              }}
            >
              View
            </Button>
          ),
          Quotes: (
            <Button
              onClick={() => {
                setTarget(i);
                setMode("quotes");
              }}
            >
              View
            </Button>
          ),
          "Criteria Details": (
            <Button
              onClick={() => {
                setTarget(i);
                setMode("criteriadetails");
              }}
            >
              View
            </Button>
          ),
          "Reject Criteria": (
            <Tag
              color={
                i?.data?.status?.toString().toLowerCase() === "passing"
                  ? "green"
                  : "red"
              }
              onClick={() => {
                setTarget(i);
                setMode("criteriadetails");
              }}
              style={{ cursor: "pointer" }}
            >
              {i?.data?.status}
            </Tag>
          ),
        }))
    : [];
  return (
    <Modal width={"100%"} open={isOpen} onCancel={onClose} onOk={onClose}>
      {mode === null || mode === "standard" ? (
        <>
          {data?.ai_analysis_status === "Not Started" ? (
            <div>
              <div style={{ marginBottom: 10, color: "black" }}>
                Extraction not started yet
              </div>
            </div>
          ) : null}
          {data?.ai_analysis_status === "Processing" ? (
            <div>
              <div style={{ marginBottom: 10, color: "royalblue" }}>
                Data is still processing, please wait..
              </div>
              <Spin size="large" />
            </div>
          ) : null}
          {data?.ai_analysis_status === "Completed" ? (
            <div style={{ height: 800, overflow: "auto" }}>
              <Typography.Title>Details for {detailId}</Typography.Title>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 10,
                }}
              >
                <Typography.Text>
                  Time Taken: {parseInt(data.ai_analysis_data.time_taken)}{" "}
                  seconds
                </Typography.Text>
                <Button icon={<FileExcelOutlined />} onClick={onDownloadOutput}>
                  Download Output
                </Button>
              </div>
              {isLoading ? (
                <Spin size="large" />
              ) : (
                <Table
                  dataSource={formattedAIData}
                  columns={colnames.map((x) => ({
                    dataIndex: x,
                    key: x,
                    label: x,
                    title: x,
                    ...(x === "Headline" ? { width: 300 } : {}),
                  }))}
                />
              )}
            </div>
          ) : null}
        </>
      ) : null}
      {mode === "metadata" ? (
        <ShowMetadata data={target} goBack={() => setMode("standard")} />
      ) : null}
      {mode === "quotes" ? (
        <ShowQuotes data={target} goBack={() => setMode("standard")} />
      ) : null}
      {mode === "otherdetails" ? (
        <ShowOtherDetails data={target} goBack={() => setMode("standard")} />
      ) : null}
      {mode === "criteriadetails" ? (
        <ShowCriteriaDetails
          requestId={detailId}
          data={target}
          goBack={() => setMode("standard")}
          forceRefresh={forceRefresh}
          onForcePass={async (item_id) => {
            let cnf = window.confirm(
              `You are about to FORCE PASS item ID for ${item_id} in batch ID ${detailId}. This action is irreversible. Are you sure you want to continue?`
            );
            if (cnf) {
              let resp = await postAuth("/api/blr_force_pass", {
                request_id: detailId,
                item_id,
              });
              if (resp.status === 200) {
                alert("Force Pass OK");
                forceRefresh();
              } else {
                alert("ERROR " + resp.status + (await resp.text()));
              }
            }
          }}
          onForceFail={async (item_id) => {
            let cnf = window.confirm(
              `You are about to FORCE FAIL item ID ${item_id} in batch ID ${detailId}. This action is irreversible. Are you sure you want to continue?`
            );
            if (cnf) {
              let resp = await postAuth("/api/blr_force_fail", {
                request_id: detailId,
                item_id,
              });
              if (resp.status === 200) {
                alert("FORCE FAIL OK");
                forceRefresh();
              } else {
                alert("ERROR " + resp.status + (await resp.text()));
              }
            }
          }}
        />
      ) : null}
    </Modal>
  );
};

const ReviewModal = ({
  articleArray = [],
  isOpen = false,
  onClose = () => {},
  onComplete = () => {},
  reviewRequestId = null,
}) => {
  const [count, setCount] = useState(0);
  const [reviewState, setReviewState] = useState(articleArray);
  const [isModified, setModified] = useState(false); // NEW

  useEffect(() => {
    if (isOpen) {
      setCount(0);
      setReviewState(articleArray);
      setModified(false); // NEW
    }
  }, [isOpen, articleArray]);

  const advance = async () => {
    if (isModified) {
      // NEW
      const confirmation = window.confirm(
        `Are you sure you want to proceed with these changes for Article ${
          count + 1
        }?`
      );
      if (!confirmation) {
        return;
      }
    }

    if (count === articleArray.length - 1) {
      const confirmation = window.confirm(
        "Are you sure you have finished reviewing all these articles?"
      );
      if (confirmation && articleArray && reviewRequestId) {
        const res = await postAuth("/api/blr_end_text_review", {
          output_json: articleArray,
          request_id: reviewRequestId,
        });
        if (res.status === 200) {
          await onComplete(reviewState);
          onClose();
        }
      }
    } else {
      setCount(count + 1);
    }
  };

  return (
    <Modal
      width={1000}
      okButtonProps={{ style: { marginLeft: 50 } }}
      okText={
        count === articleArray.length - 1
          ? "Submit All Articles"
          : "Confirm and Move To Next Article"
      }
      onOk={advance}
      open={isOpen}
      onCancel={onClose}
    >
      <div style={{ height: 500, overflow: "auto" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <Typography>
            Article {count + 1} of {articleArray.length}
          </Typography>
          <div
            style={{ display: "flex", flexDirection: "row", marginLeft: 30 }}
          >
            <Button
              style={{ marginRight: 10 }}
              icon={<ArrowLeftOutlined />}
              onClick={() => (count === 0 ? null : setCount(count - 1))}
            ></Button>
            <Button
              onClick={() =>
                count === articleArray.length - 1 ? null : setCount(count + 1)
              }
              icon={<ArrowRightOutlined />}
            ></Button>
          </div>
        </div>
        <div
          style={{
            marginBottom: 10,
            backgroundColor: "rgba(0,0,0,0.1)",
            textAlign: "left",
            padding: 10,
          }}
        >
          <Link>
            {reviewState && reviewState[count]
              ? reviewState[count]["PDF LINK"]
              : null}
          </Link>
        </div>
        <div
          style={{
            marginBottom: 10,
            backgroundColor: "rgba(0,0,0,0.1)",
            textAlign: "left",
            padding: 10,
          }}
        >
          <Typography.Text>
            HEADLINE:{" "}
            <span style={{ fontWeight: "bold" }}>
              {reviewState && reviewState[count]
                ? reviewState[count]["HEADLINE"]
                : null}
            </span>
          </Typography.Text>
        </div>
        <Input.TextArea
          style={{ height: "80%" }}
          value={
            reviewState && reviewState[count] && reviewState[count]["OCR TEXT"]
              ? reviewState[count]["OCR TEXT"]
              : null
          }
          onChange={(e) => {
            const newState = [...reviewState];
            newState[count]["OCR TEXT"] = e.target.value;
            setReviewState(newState);
            setModified(true);
          }}
        ></Input.TextArea>
      </div>
    </Modal>
  );
};

const StatusTag = ({ value }) => {
  if (value === "Not Started") return <Tag color="blue">{value}</Tag>;
  else if (value === "Pending") return <Tag color="yellow">{value}</Tag>;
  else if (value === "Processing") return <Tag color="yellow">{value}</Tag>;
  else if (value === "Completed") return <Tag color="green">{value}</Tag>;
  else if (value === "Error") return <Tag color="red">{value}</Tag>;
  else return <Tag color="white">{value}</Tag>;
};

const RequestsTable = ({
  data,
  onStartReview,
  onStartExtraction,
  onViewDetails,
}) => {
  const fmtdata = Array.isArray(data)
    ? data
        .sort(
          (a, b) =>
            new Date(b?.created_at || 0).getTime() -
            new Date(a?.created_at || 0).getTime()
        )
        .map((x) => ({
          request_id: x?.request_id,
          original_file_name: x?.original_file_name,
          file_link: (
            <Button
              onClick={async () => {
                await downloadFile(x.file_path);
              }}
            >
              Download
            </Button>
          ),
          created_at: new Date(x?.created_at).toString(),
          client_name: x?.client_name,
          ai_analysis_status: x?.ai_analysis_status ? (
            <StatusTag value={x.ai_analysis_status} />
          ) : null,
          text_review_status: <StatusTag value={x.text_review_status} />,
          actions: (
            <ButtonGroup>
              <Button
                disabled={x.text_review_status === "Completed"}
                onClick={() => onStartReview(x.request_id)}
              >
                Review
              </Button>
              <Button
                disabled={
                  x.ai_analysis_status === "Completed" ||
                  x.ai_analysis_status === "Processing"
                }
                onClick={() => onStartExtraction(x.request_id)}
              >
                Start Extraction
              </Button>
              <Button onClick={() => onViewDetails(x.request_id)}>
                Details
              </Button>
            </ButtonGroup>
          ),
        }))
    : [];
  const columns = [
    { dataIndex: "request_id", title: "Request ID", key: "ID", width: 100 },
    {
      dataIndex: "created_at",
      title: "Created At",
      key: "created_at",
      sorter: (a, b) =>
        new Date(b.created_at || 0).getTime() -
        new Date(a.created_at || 0).getTime(),
    },
    {
      dataIndex: "client_name",
      title: "Client",
      key: "client_name",
      width: 250,
      sorter: (a, b) => a.created_at.localeCompare(b.created_at),
    },
    {
      dataIndex: "original_file_name",
      title: "File Name",
      key: "original_file_name",
    },
    { dataIndex: "file_link", title: "Input File Link", key: "file_link" },
    {
      dataIndex: "text_review_status",
      title: "Text Review",
      key: "text_review_status",
    },
    {
      dataIndex: "ai_analysis_status",
      title: "AI Analysis",
      key: "ai_analysis_status",
    },

    {
      dataIndex: "actions",
      title: "Actions",
      key: "actions",
    },
  ];
  return (
    <Table
      dataSource={fmtdata}
      columns={columns}
      style={{ marginTop: 20, width: "100%", overflowX: "scroll" }}
    />
  );
};

const TopButtonRow = ({
  setAddFileModalOpen,
  setRejectCriteriaModalOpen,
  onRefresh,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <Button
        icon={<FileAddOutlined />}
        style={{ marginRight: 10 }}
        onClick={() => {
          setAddFileModalOpen(true);
        }}
      >
        Add Excel File
      </Button>
      <Button
        style={{ marginRight: 10 }}
        icon={<StopOutlined />}
        onClick={() => setRejectCriteriaModalOpen(true)}
      >
        Upload Reject Criteria
      </Button>
      <Button icon={<SyncOutlined />} onClick={onRefresh}>
        Refresh
      </Button>
    </div>
  );
};

const CommonUploadModal = ({ isOpen, onClose, text, onUploadComplete }) => {
  const [excelFile, setExcelFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [clientNames, setClientNames] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);

  useEffect(() => {
    if (isOpen) {
      setExcelFile(null);
      setUploading(false);
      if (text === "Coding Sheet") {
        getAuth("/api/blr_get_company_list")
          .then(async (r) => {
            if (r.status === 200) {
              const res = await r.json();
              if (!res)
                return alert("Client List - Request returned invalid response");
              setSelectedClient(res[0]);
              setClientNames(res);
            } else {
              alert("ERROR: Could not fetch client names");
            }
          })
          .catch((e) => {
            alert(
              "ERROR: Could not fetch client names. Please check if reject criteria was uploaded"
            );
          });
      }
    }
  }, [isOpen, text]);

  const onCancel = () => {
    setUploading(false);
    setExcelFile(null);
    onClose();
  };

  const onOk = async () => {
    if (uploading) return;
    if (!excelFile) return alert("Please upload a valid file");
    else {
      // setUploading(true);`
      if (text === "Coding Sheet") {
        const sheetCheck = await hasSheet(excelFile, "CODING SHEET");
        if (sheetCheck) {
          console.log("Check OK");
        } else {
          return alert(
            'ERROR: Invalid Format. There should be a sheet named "CODING SHEET" in the workbook.'
          );
        }
        const flr = new FileReader();
        flr.onload = async () => {
          if (!flr?.result) return alert("Error while reading file");
          if (text === "Coding Sheet" && !selectedClient)
            return alert("Error: No client selected");
          const file_base64 = flr.result.split("base64,").pop();
          const rx = await postAuth("/api/blr_upload_coding_sheet", {
            file_base64: file_base64,
            original_file_name: excelFile?.name || "untitled.xlsx",
            client_name: selectedClient,
          });
          if (rx.status === 200) {
            alert("Coding Sheet Uploaded Successfully");
            setUploading(false);
            setExcelFile(null);
            const rt = await rx.json();
            const { request_id, original_file_name, file_link } = rt;
            if (text === "Coding Sheet" && onUploadComplete) {
              onUploadComplete({
                request_id,
                original_file_name,
                file_link: `${file_link}`,
                created_at: new Date().toISOString(),
                ai_analysis_status: "Not Started",
                text_review_status: "Not Started",
                client_name: selectedClient,
              });
            }
          } else {
            alert("Error: Upload Failed With Status Code " + rx.status);
          }
          onClose();
        };
        flr.onerror = () => {
          alert("COULD NOT READ FILE");
          onClose();
        };
        flr.readAsDataURL(excelFile);
      } else if (text === "Reject Criteria") {
        const flr = new FileReader();
        flr.onload = async () => {
          if (!flr?.result) return alert("Error while reading file");
          const file_base64 = flr.result.split("base64,").pop();
          const rx = await postAuth("/api/blr_upload_reject_criteria", {
            file_base64,
          });
          if (rx.status === 200) {
            alert("Reject Criteria Uploaded Successfully");
            setUploading(false);
            setExcelFile(null);
            onClose();
          } else
            return alert("Error: Upload Failed With Status Code " + rx.status);
        };
        flr.onerror = () => {
          alert("COULD NOT READ FILE");
        };
        flr.readAsDataURL(excelFile);
      }
    }
  };

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <Modal
      open={isOpen}
      onCancel={onCancel}
      onOk={onOk}
      okText="Upload"
      okButtonProps={{
        disabled:
          excelFile && selectedClient
            ? uploading
              ? true
              : false
            : uploading
            ? true
            : false,
      }}
    >
      {uploading ? (
        <div style={containerStyle}>
          <Spin />
          <Typography.Text style={{ margin: 10, color: "royalblue" }}>
            Uploading..
          </Typography.Text>
        </div>
      ) : (
        <div style={{ ...containerStyle, alignItems: "flex-start" }}>
          <Typography.Title>Upload</Typography.Title>
          <Typography.Text style={{ marginBottom: 10 }}>
            Please select the {text} Excel file in the specified format.
          </Typography.Text>
          <Divider style={{ marginTop: 10, marginBottom: 10 }} />
          <Typography.Title level={5}>Instructions</Typography.Title>{" "}
          {text.toString() || "none"}
          {text === "Coding Sheet" ? (
            <ul>
              <li>Select 1 client below</li>
              <li>
                The Excel file must have a sheet named{" "}
                <span style={{ fontWeight: "bold" }}>"CODING SHEET"</span> with
                a column named "OCR TEXT" with the articles. There should also
                be a sheet named{" "}
                <span style={{ fontWeight: "bold" }}>"TOPICS & MESSAGES"</span>{" "}
                with all the rules.
              </li>
              <li>
                The "CODING SHEET" must have all these fields in the correct
                order: "ITEM ID", "HEADLINE", "BYLINE/COMMENTATOR", "PDF LINK",
                "OCR TEXT"
              </li>
              <li>
                Please follow these instructions strictly otherwise the
                processing might fail
              </li>
            </ul>
          ) : null}
          {text === "Reject Criteria" ? (
            <ul>
              <li>
                The reject criteria must have only 1 sheet with the company name
                followed by the reject criteria in this format:{" "}
                <code>[serial number] | [rule name] | [description]</code>.
              </li>
              <li>
                <a href="https://talentberry-public-data.s3.amazonaws.com/tagfact/bangalore_reject_criteria.xlsx">
                  Click here
                </a>{" "}
                to download a sample reject criteria file
              </li>
              <li>
                Please follow these instructions strictly otherwise the
                processing might fail
              </li>
            </ul>
          ) : null}
          <Divider />
          {text === "Coding Sheet" ? (
            <div style={{ ...containerStyle, flexDirection: "row" }}>
              <Typography.Text style={{ marginRight: 10 }}>
                Select Client:{" "}
              </Typography.Text>
              {/* <Select
                onChange={(e) => setSelectedClient(e)}
                value={selectedClient}
                style={{ marginTop: 10, marginBottom: 10, minWidth: 200 }}
                options={clientNames?.map((x) => ({ value: x, label: x }))}
                defaultValue={clientNames?.length > 0 ? clientNames[0] : null}
              ></Select> */}
              <AutoComplete
                suffixIcon={<SearchOutlined />}
                options={clientNames
                  ?.filter(
                    (x) =>
                      x.toLowerCase().indexOf(selectedClient.toLowerCase()) !==
                      -1
                  )
                  .map((x) => ({ value: x, label: x }))}
                defaultValue={clientNames?.length > 0 ? clientNames[0] : null}
                style={{ marginTop: 10, marginBottom: 10, minWidth: 200 }}
                onChange={(e) => setSelectedClient(e)}
              ></AutoComplete>
            </div>
          ) : null}
          <Upload
            accept=".xlsx"
            fileList={excelFile ? [excelFile] : []}
            onChange={(e) => setExcelFile(e.file.originFileObj)}
          >
            <Button>Select File</Button>
          </Upload>
        </div>
      )}
    </Modal>
  );
};

const BangaloreWizard = () => {
  const authCheck = useAuth();
  const [addFileModalOpen, setAddFileModalOpen] = useState(false);
  const [rejectCriteriaModalOpen, setRejectCriteriaModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);

  const [reviewModalOpen, setReviewModalOpen] = useState(false);
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [reviewRequestId, setReviewRequestId] = useState(null);
  const [detailId, setDetailId] = useState(null);
  const [reviewArticleObject, setArticleObject] = useState([]);
  const [refreshCount, setRefreshCount] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    if (!authCheck()) {
      navigate("/login");
    }
  }, [authCheck, navigate]);

  const goHome = () => navigate("/");
  useEffect(() => {
    if (tableLoading) return;
    setTableLoading(true);
    getAuth("/api/blr_get_all_requests")
      .then(async (r) => {
        if (r.status === 200) {
          const rt = await r.json();
          setTableData(rt);
        }
      })
      .catch(console.log)
      .finally(() => {
        setTableLoading(false);
      });
  }, [refreshCount]);

  return (
    <>
      <DetailModal
        detailId={detailId}
        isOpen={detailModalOpen}
        onClose={() => setDetailModalOpen(false)}
        onDownloadOutput={async () => {
          alert(
            "Download will start in a couple of minutes. Do not close this window."
          );
          postAuth("/api/blr_download_output", {
            request_id: detailId,
          })
            .then(async (r) => {
              const blob = await r.blob();
              saveAs(blob, `output_${detailId}.xlsx`);

              if (r.status === 200) {
                alert("Download OK");
              }
            })
            .catch((e) => alert(e.toString()));
        }}
      />
      <ReviewModal
        reviewRequestId={reviewRequestId}
        isOpen={reviewModalOpen}
        onClose={() => {
          setReviewModalOpen(false);
          setRefreshCount(refreshCount + 1);
        }}
        articleArray={reviewArticleObject}
      />
      <CommonUploadModal
        text="Coding Sheet"
        isOpen={addFileModalOpen}
        onClose={() => setAddFileModalOpen(false)}
        onUploadComplete={(x) => {
          if (x) setTableData([...tableData, x]);
        }}
      />
      <CommonUploadModal
        text="Reject Criteria"
        isOpen={rejectCriteriaModalOpen}
        onClose={() => setRejectCriteriaModalOpen(false)}
      />
      <Layout>
        <AppHeader />
        <Layout.Content style={{ padding: "0 48px", height: 1000 }}>
          <Breadcrumb style={{ margin: "16px 0" }}>
            <Breadcrumb.Item onClick={goHome}>
              <a href="/" onClick={(e) => e.preventDefault()}>
                Home
              </a>
            </Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => {}}>
              <a href="/blr">Bengaluru Team</a>
            </Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => {}}>
              <a href="#" style={{ color: "black" }}>
                Isentia
              </a>
            </Breadcrumb.Item>
          </Breadcrumb>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            {tableLoading ? null : (
              <TopButtonRow
                setAddFileModalOpen={setAddFileModalOpen}
                setRejectCriteriaModalOpen={setRejectCriteriaModalOpen}
                onRefresh={() => setRefreshCount(refreshCount + 1)}
              />
            )}
            {tableLoading ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  marginTop: 20,
                }}
              >
                <Spin size="large" style />
              </div>
            ) : (
              <RequestsTable
                onStartReview={async (request_id) => {
                  setReviewRequestId(request_id);
                  const resp = await postAuth("/api/blr_start_text_review", {
                    request_id,
                  });
                  if (resp.status === 200) {
                    const rt = await resp.json();
                    setArticleObject(rt);
                  }
                  setReviewModalOpen(true);
                }}
                onStartExtraction={async (request_id) => {
                  const resp = await postAuth("/api/blr_start_extraction", {
                    request_id,
                  });
                  if (resp.status === 200) {
                    alert("Extraction has been started!");
                    const newTableData = tableData.map((x) =>
                      x.request_id === request_id
                        ? { ...x, ai_analysis_status: "Processing" }
                        : x
                    );
                    setTableData(newTableData);
                  } else {
                    const rt = await resp.text();
                    alert(`ERROR: ${resp.status} - ${rt}`);
                  }
                }}
                onViewDetails={(xid) => {
                  setDetailId(xid);
                  setDetailModalOpen(true);
                }}
                data={tableData}
              />
            )}
          </div>
        </Layout.Content>
      </Layout>
    </>
  );
};

export default BangaloreWizard;
