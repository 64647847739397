import { Button, Table, Tag } from "antd";
import HYDDownloadCodingSheet from "../../../functions/HYDDownloadCodingSheet";
import arrayToExcelAndDownload from "../../../functions/saveExcel";

const HYDWizardViewInfo = ({ requestId, data, downloadFrom }) => {
  return (
    <Table
      columns={[
        { title: "Request ID", dataIndex: "request_id", key: "request_id" },
        { title: "Status", dataIndex: "status", key: "status" },
        { title: "Created At", dataIndex: "created_at", key: "created_at" },
        {
          title: "Detected",
          dataIndex: "number_of_articles_detected",
          key: "number_of_articles_detected",
        },
        {
          title: "Summaries Generated",
          dataIndex: "number_of_summaries_generated",
          key: "number_of_summaries_generated",
        },
        {
          title: "Input Sheet",
          dataIndex: "coding_sheet",
          key: "coding_sheet",
        },
        { title: "Result", dataIndex: "result", key: "result" },
      ]}
      dataSource={[
        {
          ...data,
          created_at: new Date(data.created_at).toString(),
          coding_sheet: (
            <Button
              onClick={async () => {
                await HYDDownloadCodingSheet(requestId);
              }}
            >
              Download
            </Button>
          ),
          result: (
            <Button
              onClick={() => {
                arrayToExcelAndDownload(
                  downloadFrom,
                  `tagfact-${requestId}.xlsx`
                );
              }}
            >
              Download
            </Button>
          ),
          status: (
            <Tag color={data.request_status === "Completed" ? "green" : "red"}>
              {data.request_status}
            </Tag>
          ),
        },
      ]}
      pagination={false}
    ></Table>
  );
};

export default HYDWizardViewInfo;
