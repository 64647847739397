/* eslint-disable jsx-a11y/anchor-is-valid */
import { Breadcrumb, Button, Layout, Spin, Table, Tag, Typography } from "antd";
import ButtonGroup from "antd/es/button/button-group";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppHeader from "../components/AppHeader/AppHeader";
import HYDDownloadCodingSheet from "../functions/HYDDownloadCodingSheet";
import getToken from "../functions/getToken";
import rootUrl from "../rootUrl";

const HistoryTab = () => {
  const navigate = useNavigate();

  const getBadge = (x) => {
    const badgeObj = {
      Pending: <Tag color="yellow">Pending</Tag>,
      Completed: <Tag color="green">Completed</Tag>,
      Error: <Tag color="red">Error</Tag>,
    };
    return badgeObj[x] || badgeObj["Error"];
  };
  const sampleColumns = [
    {
      title: "Request ID",
      dataIndex: "request_id",
      key: "id",
      sorter: (a, b) => (a.id || 0) - (b.id || 0),
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      // sort: true,
      sorter: (a, b) =>
        new Date(a?.created_at || 0).getTime() -
        new Date(b?.created_at || 0).getTime(),
    },
    { title: "Company", dataIndex: "company", key: "company" },
    { title: "Status", dataIndex: "status", key: "status" },
    { title: "Action", dataIndex: "action", key: "action" },
  ];

  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const getActions = (requestId) => (
      <ButtonGroup>
        <Button
          onClick={() => {
            navigate("/hyd/hyd-meltwater/view-result/" + requestId);
          }}
        >
          View Results
        </Button>
        <Button
          onClick={async () => {
            await HYDDownloadCodingSheet(requestId);
          }}
        >
          Download Inputs
        </Button>
      </ButtonGroup>
    );
    fetch(`${rootUrl}/api/hyd_get_all_requests`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    })
      .then(async (r) => {
        if (r.status === 200) {
          const abc = await r.json();
          const formatHistoryData = (x) => {
            const formattedData = x.map((i) => {
              let outputData = JSON.parse(i.output_json);
              console.log(i);
              const cnSet = new Set();
              for (let i of outputData) {
                if (i && i[0]) cnSet.add(i[0]);
              }

              let company = cnSet.size ? Array.from(cnSet).join(" / ") : "N/A";
              return {
                ...i,
                company,
                outputData,
                action: getActions(i.request_id),
                status: getBadge(i.request_status),
                created_at: new Date(i.created_at).toString(),
              };
            });
            return formattedData.sort(
              (a, b) => new Date(b.created_at) - new Date(a.created_at)
            );
          };
          const newState = formatHistoryData(abc);
          setTableData(newState);
        } else
          return alert(
            "ERROR: Request return status " +
              r.status +
              " : " +
              (await r.text())
          );
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [navigate]);

  if (loading)
    return (
      <>
        <Spin />
        <Typography.Text>Loading..</Typography.Text>
      </>
    );
  else
    return (
      <>
        <Table
          style={{ width: "100%" }}
          columns={sampleColumns}
          dataSource={tableData}
        ></Table>
      </>
    );
};

const HyderabadHistory = ({ goHome }) => {
  const navigate = useNavigate();

  return (
    <Layout>
      <AppHeader />
      <Layout.Content style={{ padding: "0 48px" }}>
        <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item onClick={() => navigate("/")}>
            <a href="#" onClick={(e) => e.preventDefault()}>
              Home
            </a>
          </Breadcrumb.Item>
          <Breadcrumb.Item onClick={() => navigate("/hyd/")}>
            <a href="#" onClick={(e) => e.preventDefault()}>
              Hyderabad Team
            </a>
          </Breadcrumb.Item>
          <Breadcrumb.Item onClick={() => navigate("/hyd/hyd-meltwater")}>
            <a
              href="#"
              onClick={(e) => e.preventDefault()}
              style={{ color: "black" }}
            >
              Hyderabad Meltwater
            </a>
          </Breadcrumb.Item>
          <Breadcrumb.Item
            onClick={() => navigate("/hyd/hyd-meltwater/history")}
          >
            <a
              href="#"
              onClick={(e) => e.preventDefault()}
              style={{ color: "black" }}
            >
              History
            </a>
          </Breadcrumb.Item>
        </Breadcrumb>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            height: 750,
          }}
        >
          <HistoryTab />
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default HyderabadHistory;
