/* eslint-disable jsx-a11y/anchor-is-valid */
import { FileAddOutlined, HistoryOutlined, StopOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  // DatePicker,
  Divider,
  Layout,
  // Table,
  Typography,
} from "antd";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AppHeader from "../components/AppHeader/AppHeader";
import useAuth from "../hooks/useAuth";
import HyderabadStats from "../components/Hyderabad/HyderabadStats";

const IntroductionTab = () => {
  const navigate = useNavigate();
  return (
    <>
      <Typography.Text strong>Hyderabad Team</Typography.Text>
      <Typography.Text style={{ marginTop: 10 }}>
        Welcome! What would you like to do?
      </Typography.Text>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          style={{
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            padding: 20,
            height: 150,
            width: 250,
            margin: 10,
          }}
          onClick={() => navigate("/hyd/hyd-meltwater/history")}
        >
          <HistoryOutlined style={{ fontSize: 35, marginBottom: 10 }} />
          <Typography.Text>Look at previous results</Typography.Text>
        </Button>
        <Button
          style={{
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            padding: 20,
            height: 150,
            width: 250,
            margin: 10,
          }}
          onClick={() => {
            navigate("/hyd/hyd-meltwater/create-new");
          }}
        >
          <FileAddOutlined style={{ fontSize: 35, marginBottom: 10 }} />
          <Typography.Text>Analyze a new set of articles</Typography.Text>
        </Button>
        <Button
          style={{
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            padding: 20,
            height: 150,
            width: 250,
            margin: 10,
          }}
          onClick={() => {
            navigate("/hyd/hyd-meltwater/upload-reject-criteria");
          }}
        >
          <StopOutlined style={{ fontSize: 35, marginBottom: 10 }} />
          <Typography.Text>Upload Reject Criteria</Typography.Text>
        </Button>
      </div>
    </>
  );
};

const HyderabadIntroduction = () => {
  const navigate = useNavigate();
  const goToRoot = () => navigate("/");
  const authCheck = useAuth();
  useEffect(() => {
    if (!authCheck()) {
      navigate("/login");
    }
  }, [authCheck, navigate]);

  return (
    <Layout style={{ minHeight: 2000 }}>
      <AppHeader />
      <Layout.Content style={{ padding: "0 48px" }}>
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item onClick={goToRoot}>
            <a href="#" onClick={(e) => e.preventDefault()}>
              Home
            </a>
          </Breadcrumb.Item>
          <Breadcrumb.Item onClick={() => navigate("/hyd")}>
            <a
              href="#"
              onClick={(e) => e.preventDefault()}
            >
              Hyderabad Team
            </a>
          </Breadcrumb.Item>
          <Breadcrumb.Item onClick={() => navigate("/hyd/hyd-meltwater")}>
            <a
              href="#"
              onClick={(e) => e.preventDefault()}
              style={{ color: "black" }}
            >
              Hyderabad Meltwater
            </a>
          </Breadcrumb.Item>
        </Breadcrumb>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            height: 750,
          }}
        >
          <IntroductionTab />
          <Divider />
          <HyderabadStats />
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default HyderabadIntroduction;
