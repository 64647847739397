/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  // StopOutlined,
  ArrowRightOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Layout,
  Progress,
  Spin,
  Typography,
  Upload,
} from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AppHeader from "../components/AppHeader/AppHeader";
import getToken from "../functions/getToken";
import rootUrl from "../rootUrl";

let intervalId = null;

const formatMinSec = (sec) => {
  const ss = sec % 60;
  const mm = (sec - ss) / 60;
  return `${mm.toString().padStart(2, "0")}:${ss.toString().padStart(2, "0")}`;
};

const CreateNewRequestTab = () => {
  const navigate = useNavigate();
  const [requestId, setRequestId] = useState(null);
  const [codingSheet, setCodingSheet] = useState(null);
  const [displayTimerSecs, setDisplayTimerSecs] = useState(0);

  const buttonLock = !codingSheet;
  const modes = { waitingForUpload: 0, uploading: 1, uploadComplete: 2 };
  const [mode, setMode] = useState(modes.waitingForUpload);

  const startTimer = () => {
    let currentTimeStart = new Date().getTime();
    intervalId = setInterval(() => {
      const diff = new Date().getTime() - (currentTimeStart || 0);
      const sec = Math.floor(diff / 1000);
      setDisplayTimerSecs(sec);
    }, 1000);
  };

  const endTimer = () => {
    clearInterval(intervalId);
    setDisplayTimerSecs(0);
  };

  const startUpload = async () => {
    if (!codingSheet) return;
    if (codingSheet?.originFileObj) {
      const fileReader = new FileReader();
      fileReader.onload = async () => {
        try {
          const fileBase64 = fileReader.result.split("base64,")[1];
          setMode(modes.uploading);
          startTimer();
          const resp = await fetch(`${rootUrl}/api/hyd_recv`, {
            method: "POST",
            mode: "cors",
            body: JSON.stringify({ fileBase64 }),
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          });
          endTimer();

          if (resp.status === 200) {
            const rj = await resp.json();
            console.log(rj);
            const { request_id } = rj;
            setRequestId(request_id);
            setMode(modes.uploadComplete);
          } else {
            alert(
              "ERROR: Request returned status" +
                resp.status +
                " - " +
                (await resp.text())
            );
            endTimer();
            setMode(modes.waitingForUpload);
          }
        } catch (e) {
          endTimer();
          setMode(modes.waitingForUpload);
          alert("ERROR: " + e.toString());
        }
      };
      fileReader.readAsDataURL(codingSheet.originFileObj);
    } else {
      alert("FAILED TO READ EXCEL FILE");
    }
  };

  if (mode === modes.waitingForUpload)
    return (
      <>
        <Typography.Title level={5}>
          Create New Analysis Request
        </Typography.Title>
        <Typography.Text
          style={{ fontWeight: "bold", textDecoration: "underline" }}
        >
          Instructions
        </Typography.Text>
        <Typography.Text>
          <ul>
            <li>
              {" "}
              Please upload the relevant documents in the required format.
            </li>
            <li>
              {" "}
              <a href="https://talentberry-public-data.s3.ap-south-1.amazonaws.com/hyderabad-team-format.xlsx">
                Click here to download an example.
              </a>{" "}
            </li>
            <li>
              {" "}
              For each company there must be two corresponding sheets in the
              Excel workbook, one with the name of the company (Rule Sheet) and
              one with the batch of articles (Article Sheet).
            </li>
            <li>
              The names of the sheets should be like "MyCompany" (Rule Sheet)
              and "MyCompany Sample Batch" (Article Sheet)
            </li>
            <li>
              The Article Sheet must have a header called "Body" and a header
              called "URL".
            </li>
            <li>
              If this format is not detected, the document will not be processed
              and it will show "Validation Failed".
            </li>
          </ul>{" "}
        </Typography.Text>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "20px",
            padding: "20px",
          }}
        >
          <Upload
            accept=".xlsx"
            // beforeUpload={(e) => setCodingSheet(e)}
            onChange={(e) => setCodingSheet(e.file)}
          >
            <Button
              style={{
                minWidth: 500,
                height: 120,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FileExcelOutlined style={{ fontSize: 40, margin: 10 }} />
              <Typography.Text>Upload Sheet</Typography.Text>
            </Button>
          </Upload>
        </div>
        <Button
          icon={<ArrowRightOutlined />}
          style={{
            marginTop: 20,
            backgroundColor: buttonLock ? "grey" : "blue",
            color: "white",
          }}
          disabled={buttonLock}
          onClick={startUpload}
        >
          Proceed
        </Button>
      </>
    );
  else if (mode === modes.uploading)
    return (
      <>
        <Spin size="large" />
        <Typography.Text
          style={{ marginTop: 10, color: "royalblue" }}
          onClick={() => setMode(modes.uploadComplete)}
        >
          Uploading Files for analysis.. Please do not refresh or close this
          browser window
        </Typography.Text>
        <Typography.Text style={{ color: "royalblue", marginTop: 10 }}>
          Time Elapsed: {formatMinSec(displayTimerSecs)}
        </Typography.Text>
        <Progress percent={parseInt((displayTimerSecs * 100) / 200)}></Progress>
      </>
    );
  else if (mode === modes.uploadComplete)
    return (
      <>
        <Typography.Title>Process Completed!</Typography.Title>
        <Typography.Text>
          Your request ID is {requestId}.{" "}
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              navigate("/hyd/hyd-meltwater/view-result/" + requestId);
            }}
          >
            Click here
          </a>{" "}
          to view.
        </Typography.Text>
      </>
    );
  else return <>404</>;
};

const HyderabadCreateNew = () => {
  const navigate = useNavigate();
  const goToRoot = () => navigate("/");

  return (
    <Layout>
      <AppHeader />
      <Layout.Content style={{ padding: "0 48px" }}>
      <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item onClick={() => navigate("/")}>
            <a href="#" onClick={(e) => e.preventDefault()}>
              Home
            </a>
          </Breadcrumb.Item>
          <Breadcrumb.Item onClick={() => navigate("/hyd/")}>
            <a href="#" onClick={(e) => e.preventDefault()}>
              Hyderabad Team
            </a>
          </Breadcrumb.Item>
          <Breadcrumb.Item onClick={() => navigate("/hyd/hyd-meltwater")}>
            <a
              href="#"
              onClick={(e) => e.preventDefault()}
            >
              Hyderabad Meltwater
            </a>
          </Breadcrumb.Item>
          <Breadcrumb.Item
            onClick={() =>
              navigate("/hyd/hyd-meltwater/create-new")
            }
          >
            <a
              href="#"
              onClick={(e) => e.preventDefault()}
              style={{ color: "black" }}
            >
              Create New
            </a>
          </Breadcrumb.Item>
        </Breadcrumb>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            height: 750,
          }}
        >
          <CreateNewRequestTab />
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default HyderabadCreateNew;
