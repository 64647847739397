import React, { useEffect, useState } from "react";
import { Modal, Table, Input, Button, Spin, Typography } from "antd";
import ExcelJS from "exceljs";
import postAuth from "../../functions/postAuth";

const BLRMeltwaterContextModal = ({ open, onCancel }) => {
  const [sheets, setSheets] = useState([]);
  const [data, setData] = useState([]);
  const [isUploadDisabled, setIsUploadDisabled] = useState(true);
  const [targetFile, setTargetFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    setTargetFile(null);
    setData([]);
    setSheets([]);
    setIsUploadDisabled(true);
  }, [open]);

  const handleUpload = () => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(targetFile);

    fileReader.onload = (e) => {
      const brx = fileReader.result;
      const file_base64 = brx.split("base64,").pop();
      console.log(data);
      postAuth("/api/blr_meltwater_upload", {
        file_base64,
        context_data: data,
      })
        .then(() => {
          setTargetFile(null);
          setData([]);
          setSheets([]);
          setIsUploadDisabled(true);
          setIsUploading(false);
          onCancel();
        })
        .catch(() => alert("ERROR"))
        .finally(() => {});
    };
    setIsUploading(true);
  };

  const handleFileChange = async (e) => {
    console.log(e.target);
    const file = e.target.files[0];
    setTargetFile(file);

    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(file);

    console.log(workbook.worksheets);

    setSheets(
      workbook.worksheets
        .map((x) => x._name.trim())
        .filter((x) => x && x.toLowerCase() !== "sentiment")
    );
  };

  const handleInputChange = (sheetName, key, value) => {
    const newData = {
      ...data,
      [sheetName]: { ...(data[sheetName] || {}), [key]: value },
    };
    setData(newData);

    const allFieldsFilled = sheets.every(
      (sheet) => newData[sheet]?.alternative_names
    );
    setIsUploadDisabled(!allFieldsFilled);
  };

  const columns = [
    {
      title: "Sheet Name",
      dataIndex: "sheetName",
    },
    {
      title: "alternative_names",
      dataIndex: "alternative_names",
      render: (_, record) => (
        <Input
          onChange={(e) =>
            handleInputChange(
              record.sheetName,
              "alternative_names",
              e.target.value
            )
          }
        />
      ),
    },
    {
      title: "additional_context",
      dataIndex: "additional_context",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <Typography.Text style={{ fontSize: 10 }}>
            Reject the article if:{" "}
          </Typography.Text>
          <Input
            onChange={(e) =>
              handleInputChange(
                record.sheetName,
                "additional_context",
                "Reject the article if " + e.target.value
              )
            }
          />
        </div>
      ),
    },
  ];

  const dataSource =
    sheets?.map((sheetName) => ({ key: sheetName, sheetName })) || [];

  return (
    <Modal
      title="Upload Excel"
      open={open}
      onCancel={onCancel}
      footer={[
        <Button
          key="upload"
          type="primary"
          disabled={isUploadDisabled}
          onClick={handleUpload}
        >
          Upload
        </Button>,
      ]}
      style={{ display: "flex" }}
      width={1200}
    >
      {isUploading ? (
        <div style={{ width: 250, height: 100 }}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          <div style={{ minWidth: 500 }}>
            <Typography.Text
              style={{ fontWeight: "normal", marginBottom: 20, marginTop: 20 }}
            >
              Sheets with "broadcast" in the name will be ignored. Any
              additional reject criteria must be put into{" "}
              <code>additonal_context</code>. Example:{" "}
              <span style={{ fontWeight: "bold" }}>
                "Reject all articles not from the state of Massachussetts"
              </span>
            </Typography.Text>
          </div>
          <div style={{ padding: 10, margin: 10 }}>
            <input type="file" onChange={handleFileChange} />
          </div>

          <Table
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            scroll={{ y: 300 }}
          />
        </>
      )}
    </Modal>
  );
};

export default BLRMeltwaterContextModal;
