import { Layout, Menu, Typography } from "antd";
import React from "react";
import "./AppHeader.css"; // Import custom styles

const { Header } = Layout;
const AppHeader = () => (
  <Header>
    <Typography.Title
      onClick={() => window.location.assign("/")}
      className="logo"
      level={4}
      style={{ color: "white", "cursor": "pointer" }}
    >
      Tagfact
    </Typography.Title>
    <Menu
      theme="dark"
      mode="horizontal"
      defaultSelectedKeys={["1"]}
      style={{ lineHeight: "64px", float: "right" }}
    >
      <Menu.Item
        key="1"
        style={{ marginRight: "0" }}
        onClick={() => {
          window.localStorage.clear();
          window.location.assign("/");
        }}
      >
        Logout
      </Menu.Item>
    </Menu>
  </Header>
);

export default AppHeader;
