import React from "react";
import { Layout, Button, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import "./HyderabadSubSelect.css";
import AppHeader from "../components/AppHeader/AppHeader";

const { Content } = Layout;

const HyderabadSubSelect = ({ setTeam = () => {} }) => {
  const navigate = useNavigate();
  return (
    <Layout>
      <AppHeader />
      <Content style={{ padding: "50px", height: 750 }}>
        <Typography.Title level={4} style={{ textAlign: "center" }}>
          Select Project
        </Typography.Title>
        <div className="button-container">
        <Button
            onClick={() => navigate("/hyd/hyd-meltwater")}
            type="primary"
            size="large"
            className="city-button"
          >
            Meltwater
          </Button>
          <Button
            onClick={() => navigate("/hyd/hyd-pharma/")}
            type="primary"
            size="large"
            className="city-button"
            // disabled={true}
          >
            Pharma
          </Button>
        </div>
      </Content>
    </Layout>
  );
};

export default HyderabadSubSelect;
