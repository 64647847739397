/* eslint-disable jsx-a11y/anchor-is-valid */
import { FileAddOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Layout,
  Modal,
  Spin,
  Typography,
  Upload,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import AppHeader from "../components/AppHeader/AppHeader";
import postAuth from "../functions/postAuth";

const ExcelUploadModal = ({ isOpen, setIsOpen }) => {
  const [fileList, setFileList] = useState([]);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    setFileList([]);
  }, [isOpen]);

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleUpload = () => {
    // Implement file upload handling logic here
    console.log(fileList[0]);
    const fileReader = new FileReader();
    fileReader.readAsDataURL(fileList[0]);

    fileReader.onload = (e) => {
      const brx = fileReader.result;
      const file_base64 = brx.split("base64,").pop();
      postAuth("/api/hyd_pharma_upload", {
        file_base64,
      })
        .then(() => {
          setIsUploading(false);
          setIsOpen(false);
          window.location.assign("/hyd/hyd-pharma/history");
        })
        .catch(() => alert("ERROR"))
        .finally(() => {
          setIsUploading(false);
          setIsOpen(false);
        });
    };
    setIsUploading(true);
    // setTimeout(() => {
    //   setIsUploading(false);
    //   setIsOpen(false);
    //   message.success("Upload successful");
    // }, 2000);
  };

  const handleBeforeUpload = (file) => {
    const isExcel =
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file.type === "application/vnd.ms-excel";
    if (!isExcel) {
      message.error("You can only upload Excel files!");
      return Upload.LIST_IGNORE;
    }
    setFileList([file]); // Only keep the latest selected file
    return false; // Prevent automatic upload
  };

  return (
    <>
      <Modal
        open={isOpen}
        title="Upload Excel File"
        onCancel={handleCancel}
        footer={
          isUploading
            ? []
            : [
                <Button key="cancel" onClick={handleCancel}>
                  Cancel
                </Button>,
                <Button
                  key="upload"
                  type="primary"
                  onClick={handleUpload}
                  disabled={!fileList.length}
                >
                  Upload
                </Button>,
              ]
        }
      >
        {isUploading ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin size="large" style={{ margin: 10 }} />
            <Typography.Text>
              Processing... Do not refresh or close this window.
            </Typography.Text>
          </div>
        ) : (
          <>
            <Upload
              beforeUpload={handleBeforeUpload}
              fileList={fileList}
              onRemove={() => setFileList([])}
              accept=".xlsx"
            >
              <Button icon={<UploadOutlined />}>Select File</Button>
            </Upload>
          </>
        )}
      </Modal>
    </>
  );
};

const HyderabadPharmaUpload = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <ExcelUploadModal isOpen={isOpen} setIsOpen={setIsOpen} />
      <Layout>
        <AppHeader />
        <Layout.Content style={{ padding: "0 48px", height: 1000 }}>
          <Breadcrumb style={{ margin: "16px 0" }}>
            <Breadcrumb.Item>
              <a href="/" onClick={() => window.location.assign("/")}>
                Home
              </a>
            </Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => {}}>
              <a href="/hyd">Hyderabad Team</a>
            </Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => {}}>
              <a href="/hyd/hyd-pharma">Hyderabad Pharma</a>
            </Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => {}}>
              <a href="#" style={{ color: "black" }}>
                Upload
              </a>
            </Breadcrumb.Item>
          </Breadcrumb>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              minHeight: 300,
            }}
          >
            <Typography.Text style={{ marginBottom: 20 }}>
              Press the button to upload an article
            </Typography.Text>
            <Button
              style={{
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "white",
                padding: 20,
                height: 150,
                width: 250,
                margin: 10,
              }}
              onClick={() => {
                setIsOpen(true);
              }}
            >
              <FileAddOutlined style={{ fontSize: 35, marginBottom: 10 }} />
              <Typography.Text>Upload</Typography.Text>
            </Button>
          </div>
        </Layout.Content>
      </Layout>
    </>
  );
};

export default HyderabadPharmaUpload;
