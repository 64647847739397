import { useEffect, useState } from "react";
import LoginForm from "../LoginForm";
import TeamSelect from "../TeamSelect";
import useAuth from "../hooks/useAuth";

const LXWrap = () => {
  const authCheck = useAuth();
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  useEffect(() => {
    setIsAuthenticated(authCheck());
  }, [authCheck]);

  if (isAuthenticated) {
    return <TeamSelect />;
  } else return <LoginForm />;
};

export default LXWrap;
