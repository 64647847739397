import { Button, DatePicker, Table, Typography } from "antd";
import { useEffect, useState } from "react";
import rootUrl from "../../rootUrl";
import CenteredSpin from "../CenteredSpin/CenteredSpin";
import dayjs from "dayjs";

const dateFormat = "YYYY-MM-DD";
const sevenDaysMilliseconds = 7 * 24 * 3600 * 1000;

const formatDate = (d) => {
  let YYYY = d.getFullYear();
  let MM = (d.getMonth() + 1).toString().padStart(2, 0);
  let DD = d.getDate().toString().padStart(2, 0);
  return `${YYYY}-${MM}-${DD}`;
};

const today = formatDate(new Date());
const oneWeekAgo = formatDate(
  new Date(new Date().getTime() - sevenDaysMilliseconds)
);
const initialState = {
  startDate: oneWeekAgo,
  endDate: today,
};
const HyderabadStats = () => {
  const [ctr, setCtr] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState(initialState);

  const [data, setData] = useState({
    number_of_articles: 0,
    number_of_companies: 0,
    breakdown: {},
  });

  useEffect(() => {
    if (isLoading) return;

    const tokenRaw = localStorage.getItem("token");
    const token = JSON.parse(tokenRaw).token;
    if (!token) {
      return;
    }

    setIsLoading(true);

    fetch(
      `${rootUrl}/api/hyd_stats?start_date=${state.startDate}&end_date=${state.endDate}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then(async (r) => {
        if (r.status === 200) {
          setData(await r.json());
        }
      })
      .catch(console.log)
      .finally(() => setIsLoading(false));
  }, [state.startDate, state.endDate, ctr]);

  if (isLoading) {
    return <CenteredSpin />;
  }
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <DatePicker
          value={dayjs(state.startDate, dateFormat)}
          onChange={(e) =>
            setState({ ...state, startDate: formatDate(e.toDate()) })
          }
          style={{ marginRight: 10 }}
          placeholder="Start Date"
          format={dateFormat}
        />
        <DatePicker
          value={dayjs(state.endDate, dateFormat)}
          onChange={(e) =>
            setState({ ...state, endDate: formatDate(e.toDate()) })
          }
          style={{ marginRight: 10 }}
          placeholder="End Date"
          format={dateFormat}
        />
        <Button
          style={{
            backgroundColor: "royalblue",
            color: "white",
            fontWeight: "bold",
          }}
          onClick={() => {
            setCtr(ctr + 1);
          }}
        >
          Find
        </Button>
      </div>
      <Typography.Text
        style={{
          marginTop: 50,
          fontWeight: "bold",
          textDecoration: "underline",
        }}
      >
        Stats from {state.startDate} to {state.endDate}
      </Typography.Text>
      <Table
        style={{ minWidth: "30%", marginTop: 20, marginBottom: 20 }}
        columns={[
          {
            title: "Number of Articles",
            dataIndex: "number_of_articles",
            key: "number_of_articles",
          },
          {
            title: "Number of Companies",
            dataIndex: "number_of_companies",
            key: "number_of_companies",
          },
        ]}
        dataSource={[
          {
            number_of_articles: (
              <Typography.Text style={{ fontWeight: "bold", fontSize: 30 }}>
                {data.number_of_articles}
              </Typography.Text>
            ),
            number_of_companies: (
              <Typography.Text style={{ fontWeight: "bold", fontSize: 30 }}>
                {data.number_of_companies}
              </Typography.Text>
            ),
          },
        ]}
        pagination={false}
      ></Table>
      <Typography.Text
        style={{
          fontWeight: "bold",
          fontSize: 18,
          textDecoration: "underline",
        }}
      >
        Breakdown
      </Typography.Text>
      <Table
        style={{ minWidth: "30%", marginTop: 20 }}
        dataSource={Object.keys(data.breakdown).map((x) => ({
          company: x,
          articles_summarized: data.breakdown[x],
        }))}
        // dataSource={Array(10)
        //   .fill(0)
        //   .map((x, i) => ({
        //     company: `Company ${i}`,
        //     articles_summarized: 12,
        //   }))}
        columns={[
          { title: "Company", key: "company", dataIndex: "company" },
          {
            title: "Articles Summarized",
            key: "articles_summarized",
            dataIndex: "articles_summarized",
          },
        ]}
        pagination={false}
      ></Table>
    </>
  );
};

export default HyderabadStats;
