import rootUrl from "../rootUrl";
import getToken from "./getToken";

const getAuth = (spath) =>
  fetch(`${rootUrl}${spath}`, {
    mode: "cors",
    method: "GET",
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
  });

export default getAuth;
