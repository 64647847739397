import { Spin } from "antd";

const CenteredSpin = ({ styles }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      ...styles,
    }}
  >
    <Spin />
  </div>
);

export default CenteredSpin;
