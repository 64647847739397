import { Button, DatePicker, Table, Typography } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import getAuth from "../../functions/getAuth";
import CenteredSpin from "../CenteredSpin/CenteredSpin";

const dateFormat = "YYYY-MM-DD";
const sevenDaysMilliseconds = 7 * 24 * 3600 * 1000;

const formatDate = (d) => {
  let YYYY = d.getFullYear();
  let MM = (d.getMonth() + 1).toString().padStart(2, 0);
  let DD = d.getDate().toString().padStart(2, 0);
  return `${YYYY}-${MM}-${DD}`;
};

const today = formatDate(new Date(new Date().getTime() + 24 * 3600 * 1000));
const oneWeekAgo = formatDate(
  new Date(new Date().getTime() - sevenDaysMilliseconds)
);
const initialState = {
  startDate: oneWeekAgo,
  endDate: today,
};
const HyderabadPharmaStats = () => {
  const [ctr, setCtr] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState(initialState);

  const [data, setData] = useState({
    number_of_social_media_articles: 0,
    number_of_trials: 0,
    number_of_conferences: 0,
    number_of_publications: 0,
    number_of_press: 0,
  });

  useEffect(() => {
    if (isLoading) return;

    const tokenRaw = localStorage.getItem("token");
    const token = JSON.parse(tokenRaw).token;
    if (!token) {
      return;
    }
    setIsLoading(true);
    getAuth(
      `/api/hyd_pharma_stats?start_date=${state.startDate}&end_date=${state.endDate}`
    )
      .then(async (r) => {
        setIsLoading(false);
        if (r.status === 200) {
          const rj = await r.json();
          setData(rj);
        }
      })
      .catch(console.log)
      .finally(() => {
        setIsLoading(false);
      });
    // ----
  }, [state.startDate, state.endDate, ctr]);

  if (isLoading) {
    return <CenteredSpin />;
  }
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <DatePicker
          value={dayjs(state.startDate, dateFormat)}
          onChange={(e) =>
            setState({ ...state, startDate: formatDate(e.toDate()) })
          }
          style={{ marginRight: 10 }}
          placeholder="Start Date"
          format={dateFormat}
        />
        <DatePicker
          value={dayjs(state.endDate, dateFormat)}
          onChange={(e) =>
            setState({ ...state, endDate: formatDate(e.toDate()) })
          }
          style={{ marginRight: 10 }}
          placeholder="End Date"
          format={dateFormat}
        />
        <Button
          style={{
            backgroundColor: "royalblue",
            color: "white",
            fontWeight: "bold",
          }}
          onClick={() => {
            setCtr(ctr + 1);
          }}
        >
          Find
        </Button>
      </div>
      <Typography.Text
        style={{
          marginTop: 50,
          fontWeight: "bold",
          textDecoration: "underline",
        }}
      >
        Stats from {state.startDate} to {state.endDate}
      </Typography.Text>
      <Table
        style={{ minWidth: "30%", marginTop: 20, marginBottom: 20 }}
        columns={[
          {
            title: "Number of Social Media Articles",
            dataIndex: "number_of_social_media_articles",
            key: "number_of_social_media_articles",
          },
          {
            title: "Number of Trials",
            dataIndex: "number_of_trials",
            key: "number_of_trials",
          },
          {
            title: "Number of Conferences",
            dataIndex: "number_of_conferences",
            key: "number_of_conferences",
          },
          {
            title: "Number of Publications",
            dataIndex: "number_of_publications",
            key: "number_of_publications",
          },
          {
            title: "Number of Press",
            dataIndex: "number_of_press",
            key: "number_of_press",
          },
        ]}
        dataSource={[
          {
            number_of_articles: (
              <Typography.Text style={{ fontWeight: "bold", fontSize: 30 }}>
                {data.number_of_articles}
              </Typography.Text>
            ),
            number_of_social_media_articles: (
              <Typography.Text style={{ fontWeight: "bold", fontSize: 30 }}>
                {data.number_of_social_media_articles}
              </Typography.Text>
            ),
            number_of_trials: (
              <Typography.Text style={{ fontWeight: "bold", fontSize: 30 }}>
                {data.number_of_trials}
              </Typography.Text>
            ),
            number_of_conferences: (
              <Typography.Text style={{ fontWeight: "bold", fontSize: 30 }}>
                {data.number_of_conferences}
              </Typography.Text>
            ),
            number_of_publications: (
              <Typography.Text style={{ fontWeight: "bold", fontSize: 30 }}>
                {data.number_of_publications}
              </Typography.Text>
            ),
            number_of_press: (
              <Typography.Text style={{ fontWeight: "bold", fontSize: 30 }}>
                {data.number_of_press}
              </Typography.Text>
            ),
          },
        ]}
        pagination={false}
      ></Table>
    </>
  );
};

export default HyderabadPharmaStats;
