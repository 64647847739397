import { Breadcrumb, Button, Layout, Typography } from "antd";
import AppHeader from "../components/AppHeader/AppHeader";
import { useNavigate } from "react-router-dom";
import ExcelFileInput from "../components/ExcelFileInput";
import { useState } from "react";
import CenteredSpin from "../components/CenteredSpin/CenteredSpin";
import getToken from "../functions/getToken";
import rootUrl from "../rootUrl";
import ExcelJS from "exceljs";
import { Buffer } from "buffer";
async function checkSheet(base64String) {
  try {
    // Create a new workbook from the base64 string
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(Buffer.from(base64String, "base64"));

    // Get the first worksheet
    const worksheet = workbook.worksheets[0];

    // Get the value in cell A1
    console.log(worksheet.getCell("A1"));
    console.log(worksheet.getCell("B1"));
    console.log(worksheet.getCell("C1"));
    return (
      worksheet.getCell("A1")?.value?.toLowerCase().trim() === "project name" &&
      worksheet.getCell("B1")?.value?.toLowerCase().trim() ===
        "reject criteria" &&
      worksheet.getCell("C1")?.value?.toLowerCase().trim() === "description"
    );
  } catch (err) {
    alert("Error:", err);
    return false;
  }
}

async function getTablePreview(base64String) {
  try {
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(Buffer.from(base64String, "base64"));

    const worksheet = workbook.worksheets[0];

    let rt = [];
    let maxRows = worksheet.rowCount;
    for (let rowCtr = 1; rowCtr <= maxRows; rowCtr++) {
      let currentRow = worksheet.getRow(rowCtr);
      let tmp = [];
      for (let cellNumber = 1; cellNumber <= 3; cellNumber++) {
        let currentCell = currentRow.getCell(cellNumber);
        if (currentCell.value) {
          tmp.push(currentCell.value.toString());
        }
      }
      if (tmp.length) rt.push(tmp);
    }
    return rt;
  } catch (err) {
    alert("Error:", err);
    return false;
  }
}

const HyderabadUploadRejectCriteria = () => {
  const navigate = useNavigate();
  const goToRoot = () => navigate("/");
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [fileBase64, setFileBase64] = useState(null);
  const [tablePreview, setTablePreview] = useState([]);

  return (
    <Layout style={{ minHeight: 2000, paddingBottom: 20 }}>
      <AppHeader />
      <Layout.Content style={{ padding: "0 48px" }}>
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item onClick={() => navigate("/")}>
            <a href="#" onClick={(e) => e.preventDefault()}>
              Home
            </a>
          </Breadcrumb.Item>
          <Breadcrumb.Item onClick={() => navigate("/hyd/")}>
            <a href="#" onClick={(e) => e.preventDefault()}>
              Hyderabad Team
            </a>
          </Breadcrumb.Item>
          <Breadcrumb.Item onClick={() => navigate("/hyd/hyd-meltwater")}>
            <a
              href="#"
              onClick={(e) => e.preventDefault()}
              style={{ color: "black" }}
            >
              Hyderabad Meltwater
            </a>
          </Breadcrumb.Item>
          <Breadcrumb.Item
            onClick={() =>
              navigate("/hyd/hyd-meltwater/upload-reject-criteria")
            }
          >
            <a
              href="#"
              onClick={(e) => e.preventDefault()}
              style={{ color: "black" }}
            >
              Upload Reject Criteria
            </a>
          </Breadcrumb.Item>
        </Breadcrumb>

        {isUploading ? (
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <CenteredSpin />
            <Typography.Text style={{ marginTop: 10, color: "blue" }}>
              Uploading, please wait..
            </Typography.Text>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <Typography.Text
              style={{ marginTop: 10, marginBottom: 10, fontWeight: "bold" }}
            >
              Please upload the criteria in the required format. The workbook
              must have three columns named "Project Name" "Reject criteria" and
              "Description" as headers in the first worksheet. The "Project
              Name" must have the full name and abbreviated name of the client
              in this format: "Alpha Beta Company/ABC"
            </Typography.Text>
            <div>
              <ExcelFileInput
                style={{ marginTop: 10 }}
                onFileUpload={async (e) => {
                  console.log(e);
                  if (await checkSheet(e.fileBase64)) {
                    const tbp = await getTablePreview(e.fileBase64);
                    setTablePreview(tbp);
                    setFileBase64(e.fileBase64);
                    setFile(e.file);
                  } else {
                    alert("Sheet is not in a valid format");
                  }
                }}
                buttonText="Select"
              />
            </div>
            {file ? (
              <>
                <Typography.Text style={{ marginTop: 20, marginRight: 10 }}>
                  Selected File: {file?.name}
                </Typography.Text>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <Button
                    style={{
                      marginTop: 10,
                      backgroundColor: "royalblue",
                      color: "white",
                      marginRight: 10,
                    }}
                    onClick={async () => {
                      setIsUploading(true);

                      try {
                        const token = await getToken();
                        const req = await fetch(
                          `${rootUrl}/api/hyd_upload_reject_criteria`,
                          {
                            method: "POST",
                            mode: "cors",
                            body: JSON.stringify({ fileBase64 }),
                            headers: {
                              Authorization: `Bearer ${token}`,
                              "Content-Type": "application/json",
                            },
                          }
                        );
                        setIsUploading(false);
                        setFile(null);
                        setFileBase64(null);
                        if (req.status === 200) {
                          alert("Uploaded successfully");
                          navigate("/hyd/hyd-meltwater");
                        } else {
                          alert(`ERROR ${req.status} : ${await req.text()}`);
                        }
                      } catch (e) {
                        setIsUploading(false);
                        alert("ERROR: " + e.toString());
                      }
                    }}
                  >
                    Upload Reject Criteria
                  </Button>
                  <Button
                    style={{
                      marginTop: 10,
                    }}
                    onClick={() => setFile(null)}
                  >
                    Cancel
                  </Button>
                </div>
                {tablePreview ? (
                  <table
                    style={{
                      backgroundColor: "white",
                      marginTop: 10,
                      borderRadius: 10,
                      width: "90%",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    {tablePreview.map((i, n) => (
                      <tr key={n}>
                        {i.map((j) => (
                          <td
                            key={j}
                            style={{
                              margin: 10,
                              padding: 10,
                              fontWeight: n === 0 ? "bold" : "normal",
                            }}
                          >
                            {j}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </table>
                ) : (
                  "."
                )}
              </>
            ) : null}
          </div>
        )}
      </Layout.Content>
    </Layout>
  );
};

export default HyderabadUploadRejectCriteria;
