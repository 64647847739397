import { Layout, Button, Typography, Breadcrumb } from "antd";
import AppHeader from "../components/AppHeader/AppHeader";
import { useNavigate } from "react-router-dom";
import { FileAddOutlined, HistoryOutlined } from "@ant-design/icons";
import HyderabadPharmaStats from "../components/Hyderabad/HyderabadPharmaStats";
const HyderabadPharmaIntroduction = ({ goHome }) => {
  const navigate = useNavigate();

  return (
    <Layout>
      <AppHeader />
      <Layout.Content style={{ padding: "0 48px", minHeight: 2000 }}>
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item onClick={() => navigate("/")}>
            <a href="#" onClick={(e) => e.preventDefault()}>
              Home
            </a>
          </Breadcrumb.Item>
          <Breadcrumb.Item onClick={() => navigate("/hyd/")}>
            <a href="#" onClick={(e) => e.preventDefault()}>
              Hyderabad Team
            </a>
          </Breadcrumb.Item>
          <Breadcrumb.Item onClick={() => navigate("/hyd/hyd-pharma")}>
            <a
              href="#"
              onClick={(e) => e.preventDefault()}
              style={{ color: "black" }}
            >
              Hyderabad Pharma
            </a>
          </Breadcrumb.Item>
        </Breadcrumb>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography.Text style={{ marginTop: 10 }}>
            Welcome! What would you like to do?
          </Typography.Text>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            style={{
              cursor: "pointer",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "white",
              padding: 20,
              height: 150,
              width: 250,
              margin: 10,
            }}
            onClick={() => navigate("/hyd/hyd-pharma/history")}
          >
            <HistoryOutlined style={{ fontSize: 35, marginBottom: 10 }} />
            <Typography.Text>Look at previous results</Typography.Text>
          </Button>
          <Button
            style={{
              cursor: "pointer",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "white",
              padding: 20,
              height: 150,
              width: 250,
              margin: 10,
            }}
            onClick={() => {
              navigate("/hyd/hyd-pharma/upload");
            }}
          >
            <FileAddOutlined style={{ fontSize: 35, marginBottom: 10 }} />
            <Typography.Text>Analyze a new set of articles</Typography.Text>
          </Button>
        </div>
        <div>
          <HyderabadPharmaStats />
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default HyderabadPharmaIntroduction;
