/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  FileAddOutlined,
  RedoOutlined
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Layout,
  Spin,
  Table,
  Tag
} from "antd";
import React, { useEffect, useState } from "react";
import AppHeader from "../components/AppHeader/AppHeader";
// import postAuth from "../functions/postAuth";
import { saveAs } from "file-saver";
import BLRMeltwaterContextModal from "../components/BangaloreMeltwater/BLRMeltwaterContextModal";
import getAuth from "../functions/getAuth";

// const ExcelUploadModal = ({ isOpen, setIsOpen }) => {
//   const [fileList, setFileList] = useState([]);
//   const [isUploading, setIsUploading] = useState(false);

//   useEffect(() => {
//     setFileList([]);
//   }, [isOpen]);

//   const handleCancel = () => {
//     setIsOpen(false);
//   };

//   const handleUpload = () => {
//     // Implement file upload handling logic here
//     console.log(fileList[0]);
//     const fileReader = new FileReader();
//     fileReader.readAsDataURL(fileList[0]);

//     fileReader.onload = (e) => {
//       const brx = fileReader.result;
//       const file_base64 = brx.split("base64,").pop();
//       postAuth("/api/blr_meltwater_upload", {
//         file_base64,
//         context_data
//       })
//         .then(() => {
//           setIsUploading(false);
//           setIsOpen(false);
//         })
//         .catch(() => alert("ERROR"))
//         .finally(() => {});
//     };
//     setIsUploading(true);
//     // setTimeout(() => {
//     //   setIsUploading(false);
//     //   setIsOpen(false);
//     //   message.success("Upload successful");
//     // }, 2000);
//   };

//   const handleBeforeUpload = (file) => {
//     const isExcel =
//       file.type ===
//         "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
//       file.type === "application/vnd.ms-excel";
//     if (!isExcel) {
//       message.error("You can only upload Excel files!");
//       return Upload.LIST_IGNORE;
//     }
//     setFileList([file]); // Only keep the latest selected file
//     return false; // Prevent automatic upload
//   };

//   return (
//     <>
//       <Modal
//         open={isOpen}
//         title="Upload Excel File"
//         onCancel={handleCancel}
//         footer={
//           isUploading
//             ? []
//             : [
//                 <Button key="cancel" onClick={handleCancel}>
//                   Cancel
//                 </Button>,
//                 <Button
//                   key="upload"
//                   type="primary"
//                   onClick={handleUpload}
//                   disabled={!fileList.length}
//                 >
//                   Upload
//                 </Button>,
//               ]
//         }
//       >
//         {isUploading ? (
//           <div
//             style={{
//               display: "flex",
//               flexDirection: "column",
//               justifyContent: "center",
//               alignItems: "center",
//             }}
//           >
//             <Spin size="large" style={{ margin: 10 }} />
//             <Typography.Text>
//               Processing... Do not refresh or close this window.
//             </Typography.Text>
//           </div>
//         ) : (
//           <>
//             <Upload
//               beforeUpload={handleBeforeUpload}
//               fileList={fileList}
//               onRemove={() => setFileList([])}
//               accept=".xlsx"
//             >
//               <Button icon={<UploadOutlined />}>Select File</Button>
//             </Upload>
//           </>
//         )}
//       </Modal>
//     </>
//   );
// };

const BangaloreMeltwater = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(false);
    getAuth("/api/blr_meltwater_history")
      .then(async (r) => {
        const res = await r.json();
        setHistoryData(res);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [refreshCounter, isOpen]);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      window.location.assign("/");
    }
  }, []);

  return (
    <>
      {/* <ExcelUploadModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        // fileList={fileList}
        // setFileList={setFileList}
      /> */}
      <BLRMeltwaterContextModal
        open={isOpen}
        onCancel={() => setIsOpen(false)}
      />
      <Layout>
        <AppHeader />

        <Layout.Content style={{ padding: "0 48px", height: 1000 }}>
          <Breadcrumb style={{ margin: "16px 0" }}>
            <Breadcrumb.Item>
              <a href="/" onClick={() => window.location.assign("/")}>
                Home
              </a>
            </Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => {}}>
              <a href="/blr">Bengaluru Team</a>
            </Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => {}}>
              <a href="#" style={{ color: "black" }}>
                Meltwater
              </a>
            </Breadcrumb.Item>
          </Breadcrumb>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Button
              icon={<FileAddOutlined />}
              style={{ marginRight: 10 }}
              onClick={() => {
                setIsOpen(true);
              }}
            >
              Upload Excel File
            </Button>
            <Button
              icon={<RedoOutlined />}
              style={{ marginRight: 10 }}
              onClick={() => setRefreshCounter(refreshCounter + 1)}
            >
              Refresh
            </Button>
          </div>
          {isLoading ? (
            <Spin />
          ) : (
            <Table
              style={{ marginTop: 10 }}
              dataSource={historyData
                .map((x) => ({
                  created_at: new Date(x.created_at).toString(),
                  request_id: x.request_id.toString(),
                  client_name: x.client_name.toString(),
                  status:
                    x && x.job_status ? (
                      <Tag
                        color={
                          x?.job_status === "error"
                            ? "red"
                            : x?.job_status === "completed"
                            ? "green"
                            : "yellow"
                        }
                      >
                        {x?.job_status.toString().toUpperCase()}
                      </Tag>
                    ) : (
                      <Tag color={"yellow"}>Pending</Tag>
                    ),
                  input_file_link: (
                    <Button
                      onClick={() => {
                        getAuth(x.input_file_link)
                          .then(async (r) => {
                            saveAs(
                              await r.blob(),
                              x.input_file_link.split("?file=").pop()
                            );
                          })
                          .catch((e) => alert(e.toString()));
                      }}
                    >
                      Download
                    </Button>
                  ),
                  output_file_link: (
                    <Button
                      onClick={() => {
                        getAuth(x.output_file_link)
                          .then(async (r) => {
                            saveAs(
                              await r.blob(),
                              x.output_file_link.split("?file=").pop()
                            );
                          })
                          .catch((e) => alert(e.toString()));
                      }}
                    >
                      Download
                    </Button>
                  ),
                }))
                .sort(
                  (a, b) =>
                    new Date(b.created_at).getTime() -
                    new Date(a.created_at).getTime()
                )}
              columns={[
                { dataIndex: "request_id", title: "Request ID" },
                {
                  dataIndex: "created_at",
                  title: "Created At",
                  sorter: (a, b) =>
                    new Date(b.created_at).getTime() -
                    new Date(a.created_at).getTime(),
                  defaultSortOrder: "ascend",
                },
                { dataIndex: "client_name", title: "Client Name" },
                { dataIndex: "status", title: "Status" },
                { dataIndex: "input_file_link", title: "Input File" },
                { dataIndex: "output_file_link", title: "Output Download" },
              ]}
            ></Table>
          )}
        </Layout.Content>
      </Layout>
    </>
  );
};

export default BangaloreMeltwater;
