import { saveAs } from "file-saver";
import getToken from "./getToken";
import rootUrl from "../rootUrl";

export default async function HYDDownloadCodingSheet(request_id) {
  try {
    const token = getToken();
    if (!token) return;
    const response = await fetch(`${rootUrl}/api/hyd_get_sheet_file_by_id`, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({ request_id }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    } else {
      const blob = await response.blob(); // Gets the blob from the response
      saveAs(blob, `${request_id}.xlsx`); // Save the file on the client machine
    }
  } catch (error) {
    alert(
      "There has been a error while fetching the file: " + error.toString()
    );
  }
}
