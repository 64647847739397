import rootUrl from "../rootUrl";
import getToken from "./getToken";

const postAuth = (spath, data) =>
fetch(`${rootUrl}${spath}`, {
  mode: "cors",
  method: "POST",
  body: JSON.stringify(data),
  headers: {
    Authorization: `Bearer ${getToken()}`,
    "Content-Type": "application/json",
  },
});

export default postAuth;