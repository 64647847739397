const getToken = () => {
  try {
    const retrievedToken = localStorage.getItem("token");
    if (retrievedToken) {
      const parsedTokenData = JSON.parse(retrievedToken);
      if (parsedTokenData) {
        let rv = parsedTokenData?.token;
        if (!rv) {
          alert("ERROR : token not found");
          throw new Error("token not found");
        }
        return rv;
      } else {
        alert("ERROR : token not found");
        throw new Error("token not found");
      }
    }
  } catch (exception) {
    console.error(exception);
    alert("ERROR : invalid token");
    window.localStorage.clear();
    window.location.assign("/");
    // throw new Error("invalid token");
  }
};

export default getToken;
