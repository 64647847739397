import { LinkOutlined } from "@ant-design/icons";
import { Button, Table, Tag } from "antd";

const fmtx = (data) => {
  return data.map((i) => ({
    serial: i[1] ? i[1] : "N/A",
    company: i[0],
    summary: i[2],
    sentiment: i[3],
    article_body: i[4]
      ? i[4]?.substring(0, 300) + " (...)"
      : "validation failed",
    article_url: i[5],
    relevance: i[6],
  }));
};
const HYDWizardArticleTable = ({ data }) => {
  const formattedData = fmtx(data);
  return (
    <>
      <Table
        style={{ width: "100%", border: "1px solid rgba(0,0,0,0.1)" }}
        columns={[
          {
            title: "S. No.",
            dataIndex: "serial",
            key: "serial",
            sorter: (a, b) =>
              (a || "").toString().localeCompare((b || "").toString()),
          },
          {
            title: "Company",
            dataIndex: "company",
            key: "company",
            sorter: (a, b) =>
              (a || "").toString().localeCompare((b || "").toString()),
          },
          {
            title: "Article Text",
            dataIndex: "article_body",
            key: "article_body",
            width: 150,
          },
          {
            title: "Article URL",
            dataIndex: "article_url",
            width: 150,
            key: "article_url",
            render: (record) =>
              record ? (
                <Button
                  icon={<LinkOutlined />}
                  onClick={() => {
                    return record ? window.open(record, "_blank") : null;
                  }}
                >
                  Open Article Link
                </Button>
              ) : null,
          },
          {
            title: "Summary",
            dataIndex: "summary",
            key: "summary",
            width: 400,
          },
          {
            title: "Sentiment",
            dataIndex: "sentiment",
            key: "sentiment",
            render: (s) =>
              s ? (
                <Tag
                  color={
                    s === "POSITIVE"
                      ? "green"
                      : s === "NEGATIVE"
                      ? "red"
                      : "yellow"
                  }
                >
                  {s}
                </Tag>
              ) : null,
          },
          {
            title: "Relevance",
            dataIndex: "relevance",
            key: "relevance",
            render: (s) =>
              s ? (
                <Tag color={s.toLowerCase() === "relevant" ? "green" : "red"}>
                  {s}
                </Tag>
              ) : null,
          },
        ]}
        dataSource={formattedData}
      ></Table>
    </>
  );
};

export default HYDWizardArticleTable;
