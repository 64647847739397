import React from "react";
import { Upload, Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";

const ExcelFileInput = ({
  onFileUpload,
  buttonText = "Upload Excel File",
  style = {},
}) => {
  const handleUpload = (file) => {
    if (
      file.type !==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      message.error("Invalid file format. Please upload a .xlsx file");
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      const base64DataUrl = reader.result;
      onFileUpload({ file, fileBase64: base64DataUrl.split("base64,").pop() });
    };
    reader.readAsDataURL(file);
  };

  return (
    <Upload beforeUpload={handleUpload} showUploadList={false} accept=".xlsx">
      <Button style={style} icon={<UploadOutlined />}>
        {buttonText}
      </Button>
    </Upload>
  );
};

export default ExcelFileInput;
