/* eslint-disable jsx-a11y/anchor-is-valid */
import { Breadcrumb, Layout, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppHeader from "../components/AppHeader/AppHeader";
import CenteredSpin from "../components/CenteredSpin/CenteredSpin";
import HYDWizardArticleTable from "../components/Wizard/HYDWizardArticleTable";
import HYDWizardViewInfo from "../components/Wizard/HYDWizardViewInfo";
import getToken from "../functions/getToken";
import useAuth from "../hooks/useAuth";
import rootUrl from "../rootUrl";

const ViewResultTab = ({ requestId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [vrState, setVRState] = useState(null);
  useEffect(() => {
    const token = getToken();
    if (!token) return alert("NO LOGGED IN");
    if (!requestId) return;
    setIsLoading(true);
    fetch(`${rootUrl}/api/hyd_get_request_by_id`, {
      mode: "cors",
      method: "POST",
      body: JSON.stringify({ request_id: requestId }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then(async (r) => {
        const rj = await r.json();
        console.log(rj);
        setVRState(rj);
      })
      .catch(console.log)
      .finally(() => setIsLoading(false));
  }, [requestId]);
  if (isLoading) {
    <CenteredSpin />;
  } else
    return (
      <>
        <Typography.Title level={4}>Analysis Results</Typography.Title>
        <HYDWizardViewInfo
          requestId={requestId}
          data={vrState?.view_info || {}}
          downloadFrom={vrState?.table_info}
        />
        <Typography.Title level={4}>Articles</Typography.Title>
        <HYDWizardArticleTable data={vrState?.table_info || []} />
      </>
    );
};

const HyderabadViewResult = () => {
  const navigate = useNavigate();
  const authCheck = useAuth();
  const [requestId, setRequestId] = useState(null);
  const goToRoot = () => navigate("/");

  useEffect(() => {
    const reqid = window.location.pathname.split("/")?.pop();
    if (reqid) {
      setRequestId(reqid);
    }
  }, []);

  useEffect(() => {
    if (!authCheck()) {
      navigate("/login");
    }
  }, [authCheck, navigate]);
  return (
    <Layout>
      <AppHeader />
      <Layout.Content style={{ padding: "0 48px" }}>
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item onClick={goToRoot}>
            <a href="#" onClick={(e) => e.preventDefault()}>
              Home
            </a>
          </Breadcrumb.Item>
          <Breadcrumb.Item onClick={() => navigate("/hyd/hyd-meltwater")}>
            <a href="#" onClick={(e) => e.preventDefault()}>
              Hyderabad Team
            </a>
          </Breadcrumb.Item>
          <Breadcrumb.Item onClick={() => navigate("/hyd/hyd-meltwater/history")}>
            <a href="#" onClick={(e) => e.preventDefault()}>
              History
            </a>
          </Breadcrumb.Item>
          <Breadcrumb.Item onClick={() => navigate("/hyd/hyd-meltwater")}>
            <a
              href="#"
              onClick={(e) => e.preventDefault()}
              style={{ color: "black" }}
            >
              {requestId?.trim()}
            </a>
          </Breadcrumb.Item>
        </Breadcrumb>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            height: 750,
          }}
        >
          <ViewResultTab requestId={requestId} />
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default HyderabadViewResult;
